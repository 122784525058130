import PropTypes from 'prop-types';

import { useEffect } from 'react';
import {
  Form, Input, Button, Row, Col, Card,
} from 'antd';
import {
  MailOutlined, UserOutlined, LockOutlined, UnlockOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from '../../Login/index.module.scss';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

const RegisterForm = ({
  onSubmit, formRef, submitting, initialValues,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    formRef.current = form;
  }, [formRef, form]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name='register'
      onFinish={onSubmit}
      scrollToFirstError
      initialValues={initialValues}
      className={styles.authForm}
    >
      <Card title='Registrieren' bordered level={4} className={styles.title}>
        <Row gutter={[16, 16]} className={styles.row}>
          <Col span={24}>
            <Form.Item
              className={styles.formItemAlignment}
              name='first_name'
              rules={[{
                required: true,
                message: 'Bitte geben Sie Ihren Namen ein!',
              }]}
            >
              <Input
                className='input'
                placeholder='Name'
                prefix={<UserOutlined className='icon' />}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              className={styles.formItemAlignment}
              name='last_name'
              rules={[{
                required: true,
                message: 'Bitte geben Sie Ihren Familienname ein!',
              }]}
            >
              <Input
                className='input'
                placeholder='Familienname'
                prefix={<UserOutlined className='icon' />}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              className={styles.formItemAlignment}
              name='email'
              rules={
          [
            {
              required: true,
              message: 'Bitte geben Sie ihre E-Mail-Adresse ein!',
            },
            {
              type: 'email',
              message: 'Die Eingabe ist keine gültige E-Mail!',
            },
          ]
        }
            >
              <Input
                className={styles.input}
                placeholder='Email'
                prefix={<MailOutlined className='icon' />}
              />
            </Form.Item>
          </Col>
          <Col span={24}>

            <Form.Item
              className={styles.formItemAlignment}
              name='password1'
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie das Passwort nochmals ein.',
                },
              ]}
            >
              <Input.Password
                className={styles.input}
                placeholder='Passwort'
                prefix={<LockOutlined className='icon' />}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              className={styles.formItemAlignment}
              name='password2'
              dependencies={['password1']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie das Passwort nochmals ein.',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password1') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error('Die beiden eingegebenen Passwörter stimmen nicht überein.'));
                  },
                }),
              ]}
            >
              <Input.Password
                className={styles.input}
                placeholder='Passwort bestätigen'
                prefix={<UnlockOutlined className='icon' />}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              style={{ textAlign: 'center' }}
            >
              <Link to='/login'>Sie haben bereits ein Konto?</Link>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button type='primary' htmlType='submit' className={styles.buttonAlign} loading={submitting}>
                Registrieren
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

RegisterForm.propTypes = {
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  formRef: PropTypes.object,
  initialValues: PropTypes.object,
};
RegisterForm.defaultProps = {
  onSubmit: () => {},
  submitting: false,
  formRef: { current: null },
  initialValues: {},
};

export default RegisterForm;
