import {
  Button,
  Card, Col, Input, PageHeader, Radio, Row, Space, Spin,
  Alert,
} from 'antd';

import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import { globalLoadingSelector, userAssessmentsSelector } from '../../store/assessments/selectors';

import styles from './index.module.scss';

const { TextArea } = Input;

const Explanation = () => {
  const [assessment, setAssessment] = useState({});
  const assessments = useSelector(userAssessmentsSelector);
  const history = useHistory();

  const globalLoading = useSelector(globalLoadingSelector);
  const getStarted = () => {
    history.push(`/assessment/${assessment.slug}`);
  };

  const titleContent = (
    <Row>
      <Col>
        <h1 className={styles.title}>{assessment.title || 'no title'}</h1>
      </Col>
      <Col>
        <p className={styles.welcome}>
          Willkommen zum
          {' '}
          {' '}
          {assessment.title || 'no title'}
          !
        </p>
        <p className={styles.explanation}>
          Sie wurden als Bewerter für das
          {' '}
          {assessment.title || 'no title'}
          {' '}
          ausgewählt. Bitte bewerten Sie die
          Aussagen/Fragen der Ihnen zugewiesenen Bewertungskategorien. Folgende Bewertungsmöglichkeiten sind Teil
          des Assessments:
        </p>
      </Col>
    </Row>
  );

  useEffect(() => {
    setAssessment(assessments[0] || {});
  }, [assessments]);

  return (
    <div className={classNames({ [styles.container]: globalLoading })}>
      <Spin spinning={globalLoading}>
        {!globalLoading && (
        <>
          {assessments.length
            ? (
              <>
                <PageHeader title={titleContent} className={styles.pageHeader} />
                <div className={styles.content}>
                  <Row justify='space-around'>
                    <Col span={5} md={14} sm={24} xs={24} xxl={5} xl={7} lg={9}>
                      <Card
                        title='Wertung'
                        className={styles.answerExplain}
                      >
                        <Radio.Group defaultValue='0' disabled>
                          <Space direction='vertical'>
                            <Radio value='0'>0</Radio>
                            <Radio value='1'>5</Radio>
                            <Radio value='2'>8</Radio>
                            <Radio value='3'>10</Radio>
                          </Space>
                        </Radio.Group>
                      </Card>
                    </Col>
                    <Col span={5} md={14} sm={24} xs={24} xxl={5} xl={7} lg={9}>
                      <Card
                        title='Antwortauswahl'
                        className={styles.answerExplain}
                      >
                        <Radio.Group defaultValue='0' disabled>
                          <Space direction='vertical'>
                            <Radio value='0'>Option A</Radio>
                            <Radio value='1'>Option B</Radio>
                            <Radio value='2'>Option C</Radio>
                            <Radio value='3'>Option D</Radio>
                          </Space>
                        </Radio.Group>
                      </Card>
                    </Col>
                    <Col span={5} md={14} sm={24} xs={24} xxl={5} xl={7} lg={9}>
                      <Card
                        title='Freitext'
                        bordered
                        className={styles.answerExplain}
                      >
                        <TextArea readOnly placeholder='Autosize height based on content lines' />
                      </Card>
                    </Col>
                    <Col span={5} md={14} sm={24} xs={24} xxl={5} xl={7} lg={9}>
                      <Row className={styles.answer}>
                        <Col className={styles.no}>
                          <span>keine Antwort möglich</span>
                        </Col>
                        <Col>
                          <Card
                            title='Ja/Nein'
                          >
                            <Radio.Group defaultValue='0' disabled>
                              <Space direction='vertical'>
                                <Radio value='0'>JA</Radio>
                                <Radio value='1'>NEIN</Radio>
                              </Space>
                            </Radio.Group>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row justify='center'>
                    <Col>
                      <Button type='primary' onClick={getStarted}>OKAY, Los geht´s!</Button>
                    </Col>
                  </Row>
                </div>
              </>
            )
            : (
              <div className={styles.noAssessment}>
                <Alert
                  message='Information'
                  description='Aktuell ist kein Assessment für diesen Benutzer freigeschalten.'
                  type='info'
                  showIcon
                />
              </div>
            )}
        </>
        )}
      </Spin>
    </div>
  );
};

export default Explanation;
