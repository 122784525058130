import PropTypes from 'prop-types';

import { useEffect } from 'react';

import {
  Card, Row, Col, List, message,
} from 'antd';

import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { assessmentsSelector } from '../../../store/assessments/selectors';
import { fetchAssessments, resetStatusAssessment } from '../../../store/assessments/actions';

import styles from '../index.module.scss';

const IcpAssessment = ({ activeTab }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { customerSlug } = useParams();

  const assessment = useSelector(assessmentsSelector);

  const goUsersProgress = async (item) => {
    dispatch(await resetStatusAssessment());
    history.push({ pathname: `/wizard/${item.slug}`, state: { company: item.company } });
  };

  useEffect(() => {
    if (activeTab === 'icp-assessments') {
      dispatch(fetchAssessments()).then((res) => {
        if (res.error) {
          if (res.error?.response?.data?.error) {
            message.error(res.error.response.data.error);
          } else {
            message.error('General server error');
          }
        }
      });
    }
  }, [activeTab, customerSlug, dispatch]);

  return (
    <div>
      <Card title='Assessments' bordered={false} className={styles.assessmentCard}>
        <List
          pagination
          dataSource={assessment.filter((item) => (item.company === customerSlug && item.state !== 'WEIGHTING'))}
          renderItem={(item, idx) => (
            <Row key={idx.toString()} style={{ display: 'flex', flexDirection: 'column' }}>
              <List.Item style={{ padding: '10px 10px 10px 30px', borderBottom: '1px solid #f0f0f0' }}>
                <Col span={12}>
                  <span>{item.title || 'no title'}</span>
                </Col>
                <Col span={12} className={styles.actionsContainer}>
                  <div className={styles.assessmentStatus}>{`(${item.state})`}</div>
                  <span
                    onClick={() => goUsersProgress(item)}
                    className={styles.actionLink}
                    role='presentation'
                  >
                    Anzeigen
                  </span>
                </Col>
              </List.Item>
            </Row>
          )}
        />
      </Card>
    </div>
  );
};

IcpAssessment.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

export default IcpAssessment;
