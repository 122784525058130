import PropTypes from 'prop-types';

import {
  useEffect, useMemo, useState,
} from 'react';
import {
  NavLink, useHistory, useLocation, useParams,
} from 'react-router-dom';

import {
  Image,
  Layout, Menu, message, Tooltip,
} from 'antd';
import {
  MenuFoldOutlined, MenuUnfoldOutlined,
} from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';

import Logo from './Logo';

import styles from '../index.module.scss';
import { assessmentsSelector, userAssessmentsSelector } from '../../../../store/assessments/selectors';

import { fetchAssessmentCategories, responseHandler } from '../../../../utils/helpers';

import { userSelector } from '../../../../store/auth/selectors';
import { fetchCategories } from '../../../../store/categories/actions';
import { changeGlobalLoading, setUserAssessments } from '../../../../store/assessments/actions';

const { Sider } = Layout;
const { SubMenu } = Menu;

const UserSidebar = ({ collapsed, onChange }) => {
  const [data, setData] = useState([]);

  const userAssessments = useSelector(userAssessmentsSelector);
  const assessments = useSelector(assessmentsSelector);
  const user = useSelector(userSelector);
  const openedSubMenus = [];
  const selectedKeys = [];

  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { slug } = useParams();

  const CollapseIcon = useMemo(() => (collapsed ? MenuUnfoldOutlined : MenuFoldOutlined), [collapsed]);

  const isPathContains = (regexes) => regexes.some((regex) => regex.test(pathname));
  if (isPathContains([/^\/assessment/])) {
    openedSubMenus.push(`/assessment/${slug}`);
    selectedKeys.push(pathname);
    selectedKeys.push(`${pathname}/categories`);
  }

  // eslint-disable-next-line consistent-return
  const handleRoutChanges = (category, assessment) => {
    if (`/assessment/${assessment.slug}/${category.slug}` === pathname) {
      return false;
    }
    history.push(`/assessment/${assessment.slug}/${category.slug}`);
  };

  const renderAssessments = (assessment, category) => {
    if (category.is_assigned) {
      return (
        <Menu.Item key={`/assessment/${assessment.slug}/${category.slug}`} className={styles.navContainer}>
          <Tooltip placement='topRight' title={category.name}>
            <span onClick={() => handleRoutChanges(category, assessment)} role='presentation'>
              <div style={{ height: '55px', display: 'flex' }}>
                <div className={styles.iconTable}>
                  <Image preview={false} className={styles.img} src={category.image} alt='Icon' />
                </div>
                <div>
                  [
                  {category?.questions_count || 0}
                  /
                  {category.answered_questions_count || 0}
                  ]
                </div>
              </div>
            </span>
          </Tooltip>
        </Menu.Item>
      );
    }
    return null;
  };
  const renderMenu = (assessment) => (
    <SubMenu
      key={`/assessment/${assessment.slug}`}
      title={assessment.title || 'No title'}
      className={styles.subTitle}
    >
      <Menu.Item key={`/assessment/${assessment.slug}/categories`} className={styles.navContainer}>
        <NavLink to={`/assessment/${assessment.slug}`}>Übersicht</NavLink>
      </Menu.Item>
      {(assessment?.categories || []).map((category) => renderAssessments(assessment, category))}
    </SubMenu>
  );

  useEffect(() => {
    const filtered = assessments.filter((item) => item.state === 'STARTED') || [];
    if (filtered.length) {
      try {
        (async () => {
          const allCategories = await dispatch(fetchCategories()).then((res) => {
            if (res.error) {
              responseHandler(res);
            }
            return res.data;
          });
          const allAssessments = await fetchAssessmentCategories(filtered, user?.slug).then((res) => res);
          const result = allAssessments.map((assessment) => {
            const arr = allCategories.reduce((source, category) => {
              const myCategory = assessment.categories.find((item) => item.category.slug === category.slug);
              if (!myCategory) {
                return source.concat({
                  is_assigned: false,
                  ...category,
                });
              }
              const item = { ...category };
              if (myCategory) {
                Object.assign(item, {
                  ...myCategory, is_assigned: true,
                });
              }
              return source.concat(item);
            }, []);
            return {
              ...assessment, categories: arr,
            };
          });
          dispatch(setUserAssessments(result));
          dispatch(changeGlobalLoading(false));
        })();
      } catch (e) {
        message.error('General server error');
      }
    } else {
      dispatch(changeGlobalLoading(false));
    }
  }, [dispatch, assessments, user.slug]);

  useEffect(() => {
    setData(userAssessments);
  }, [userAssessments]);

  return (
    <>
      <Sider
        trigger={(
          <div className={styles.trigger}>
            <CollapseIcon
              onClick={onChange}
            />
          </div>
        )}
        collapsible
        collapsed={collapsed}
        theme='light'
        className={collapsed ? styles.closeSider : styles.sidebar}
        width={312}
        collapsedWidth={150}
      >
        <Logo collapsed={collapsed} />
        <Menu
          defaultSelectedKeys={[pathname]}
          defaultOpenKeys={openedSubMenus}
          selectedKeys={selectedKeys}
          theme='dark'
          mode='inline'
          className={styles.userMenu}
        >
          {data && data.map(renderMenu)}
        </Menu>
      </Sider>
    </>
  );
};

UserSidebar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UserSidebar;
