import {
  useCallback, useEffect, useRef,
} from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import LoginForm from './components/LoginForm';
import TwoFaForm from './components/TwoFaForm';
import { loginUser, submitTwoFa } from '../../store/auth/actions';
import {
  isAuthorized, isSubmitting, isTwoFaVerified, userSelector,
} from '../../store/auth/selectors';

import { responseHandler } from '../../utils/helpers';

import styles from './index.module.scss';

const Login = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const submitting = useSelector(isSubmitting);
  const authorized = useSelector(isAuthorized);
  const twoFaVerified = useSelector(isTwoFaVerified);
  const history = useHistory();
  const form = useRef(null);

  useEffect(() => {
    if (authorized && (!user.is_2fa_enabled || twoFaVerified)) {
      history.push('/');
    }
  }, [authorized, user, history, twoFaVerified]);

  const handleLoginSubmit = useCallback((formData) => {
    dispatch(loginUser(formData)).then((res) => responseHandler(res));
  }, [dispatch]);

  const handleTwoFaSubmit = useCallback((formData) => {
    dispatch(submitTwoFa(formData)).then((res) => responseHandler(res));
  }, [dispatch]);

  return (
    <Row justify='center' className={styles.container}>
      <Col xs={18} sm={16} md={12} lg={10} xl={8} xxl={6}>
        {user.is_2fa_enabled && authorized
          ? (
            <TwoFaForm
              submitting={submitting}
              formRef={form}
              onSubmit={handleTwoFaSubmit}
            />
          )
          : (
            <LoginForm
              submitting={submitting}
              formRef={form}
              onSubmit={handleLoginSubmit}
            />
          )}
      </Col>
    </Row>
  );
};

export default Login;
