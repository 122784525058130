import {
  Button,
  Col, message, PageHeader, Row, Spin,
} from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import queryString from 'query-string';

import Stepper from '../../components/common/Stepper/Stepper';
import Question from './components/Question/Question';

import { userSelector } from '../../store/auth/selectors';
import { userAssessmentsSelector } from '../../store/assessments/selectors';

import { fetchAllQuestions } from '../../store/questions/actions';
import { fetchAllAnswers } from '../../store/answers/actions';
import { updateUserAssessments } from '../../store/assessments/actions';

import styles from './index.module.scss';

const UserCategoryQuestions = () => {
  const [category, setCategory] = useState({});
  const [currentQuestion, setQuestion] = useState({});
  const [questions, setQuestions] = useState([]);
  const [defaultQuestions, setDefaultQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLastQuestion, setLast] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const questionRef = useRef(queryString.parse(location.search).question);
  const { slug, categorySlug } = useParams();

  const user = useSelector(userSelector);
  const assessments = useSelector(userAssessmentsSelector);

  const backToCategories = () => {
    history.push(`/assessment/${slug}`);
  };

  const allQuestions = useMemo(() => {
    const arr = questions.map((item) => {
      if (item.is_skipped && item?.answer === 'false' && item.type === 'BOOLEAN') {
        return {
          ...item,
          status: 'finish',
        };
      }
      if (item.is_skipped && item.type !== 'BOOLEAN') {
        return {
          ...item,
          status: 'skip',
        };
      }
      if (!item.is_answered) {
        return {
          ...item,
          status: 'wait',
        };
      }
      if (item.is_answered) {
        return {
          ...item,
          status: 'finish',
        };
      }
      return item;
    });
    const last = arr.find((item, ind) => ind === arr.length - 1);
    if ((last && currentQuestion) && last?.slug === currentQuestion?.slug) {
      setLast(true);
    } else {
      setLast(false);
    }
    return arr.map((item) => (item.slug === currentQuestion?.slug ? { ...currentQuestion, status: 'process' } : item));
  }, [questions, currentQuestion]);

  const statuses = useMemo(() => allQuestions.map(({ status }) => status), [allQuestions]);

  const titleContent = (
    <div className={styles.wrapper}>
      <Col>
        <Button type='primary' onClick={backToCategories}>Zurück</Button>
      </Col>
      <Row className={styles.scroll}>
        <Row>
          <Col className={styles.title}>
            <h1>{category?.category?.name || ''}</h1>
          </Col>
          <Stepper statuses={statuses} />
        </Row>
      </Row>
    </div>
  );

  const updateState = useCallback((question) => {
    const all = questions.map((item) => {
      if (item.slug === question.slug) {
        return question;
      }
      if (question.is_skipped && question.logic.skip.includes(item.slug)) {
        const search = defaultQuestions.find((it) => it.slug === item.slug);
        return { ...search, is_skipped: true };
      }
      if (!question.is_skipped && question.logic.skip.includes(item.slug)) {
        return { ...item, is_skipped: false };
      }
      return item;
    });
    if (question?.isSavedProgress) {
      history.replace({
        pathname: `/assessment/${slug}/${categorySlug}`,
        search: `?question=${question.slug}`,
        state: { current: question, all },
      });
    } else {
      const index = all.map((item) => item.slug).indexOf(question.slug);
      const current = all.slice(index + 1).find((it) => !it.is_skipped || it.logic.skip.length > 0);
      const answerCount = all.filter((item) => item.is_answered).length;
      // eslint-disable-next-line max-len
      const skippedCount = all.filter((item) => item.is_skipped && !item.is_answered && item.logic?.skip?.length).length;
      dispatch(updateUserAssessments({ categorySlug, assessment: slug, answerCount: answerCount + skippedCount }));
      history.replace({
        pathname: `/assessment/${slug}/${categorySlug}`,
        search: `?question=${question.slug}`,
        state: { current: question, all },
      });
      if (current) {
        history.push(`/assessment/${slug}/${categorySlug}?question=${current.slug}`, { current, all });
      } else {
        history.push(`/assessment/${slug}`);
      }
    }
  }, [categorySlug, defaultQuestions, dispatch, history, questions, slug]);

  const updateQuestions = useCallback((question) => {
    const index = allQuestions.map((item) => item.slug).indexOf(question.slug);
    const sliced = allQuestions.slice(0, index).reverse();
    const current = sliced.find((item) => {
      if (((Array.isArray(item.logic.skip) && item.logic.skip.length > 0) || !item.is_skipped)) {
        return item;
      }
      return null;
    });
    if (current) {
      const all = questions.map((item) => {
        if (item.slug === current.slug) {
          return {
            ...current,
          };
        }
        return item;
      });
      history.push(`/assessment/${slug}/${categorySlug}?question=${current.slug}`, { current, all });
    } else {
      history.push(`/assessment/${slug}`);
    }
  }, [allQuestions, questions, history, slug, categorySlug]);

  useEffect(() => {
    const currentAssessment = assessments.find((item) => item.slug === slug);
    const currentCategory = currentAssessment?.categories.find((item) => item.slug === categorySlug);
    setCategory(currentCategory);
  }, [assessments, slug, categorySlug]);

  useEffect(() => {
    history.listen((loc) => {
      setQuestion(loc?.state?.current || {});
      setQuestions(loc?.state?.all || []);
    });
  }, [history]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const allQ = await dispatch(fetchAllQuestions({ assessmentSlug: slug, categorySlug }))
          .then((res) => res.data.sort((a, b) => a.order - b.order));
        setDefaultQuestions(allQ);
        const answers = await dispatch(fetchAllAnswers()).then((res) => res.data);
        const arr = allQ.reduce((source, question) => {
          const myAnswer = answers.find(
            (item) => item.question === question.slug && item.user === user.slug,
          );
          if (!myAnswer) {
            return source.concat({
              ...question,
              is_skipped: false,
              is_answered: false,
              answer: '',
            });
          }
          const item = { ...question };
          if (myAnswer) {
            Object.assign(item, {
              ...myAnswer, slug: myAnswer.question, question: question.question, answerSlug: myAnswer.slug,
            });
          }
          return source.concat(item);
        }, []);
        const ids = [...new Set(
          Array.prototype.concat.apply([], arr.filter((question) => question.is_skipped)
            .map(({ logic }) => logic.skip)),
        )];
        const result = arr.map((item) => {
          if (ids.includes(item.slug)) {
            return {
              ...item,
              is_skipped: true,
            };
          }
          return item;
        });
        if (questionRef?.current) {
          const searchQuestion = result.find((item) => item.slug === questionRef.current);
          history.push(`/assessment/${slug}/${categorySlug}?question=${questionRef.current}`,
            { current: searchQuestion, all: result });
        } else {
          const current = result?.find((item) => (!item.is_answered && !item.is_skipped));
          if (current) {
            setQuestion(current);
            history.replace({
              pathname: `/assessment/${slug}/${categorySlug}`,
              search: `?question=${current.slug}`,
              state: { current, all: result },
            });
          } else {
            history.replace({
              pathname: `/assessment/${slug}/${categorySlug}`,
              search: `?question=${result[0]?.slug}` || '',
              state: { current: result[0] || '', all: result },
            });
          }
        }
      } catch (e) {
        setLoading(false);
        return message.error('General server error');
      }
      return setLoading(false);
    })();
    return () => {
      questionRef.current = null;
      setQuestions([]);
      setQuestion({});
      setLoading(false);
    };
  }, [categorySlug, dispatch, history, slug, user]);

  return (
    <Spin spinning={loading}>
      <PageHeader title={titleContent} className={styles.pageHeader} />
      <Question
        allQuestions={allQuestions}
        question={currentQuestion}
        update={updateState}
        last={isLastQuestion}
        goBack={updateQuestions}
      />
    </Spin>
  );
};
export default UserCategoryQuestions;
