import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'antd/dist/antd.css';
import deDE from 'antd/lib/locale/de_DE';

import './styles/index.scss';
import { ConfigProvider } from 'antd';

import store from './store';
import Router from './router/Router';

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={deDE}>
      <Router />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root'),
);
