import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import classNames from 'classnames';

import styles from '../index.module.scss';

const Logo = ({ src, collapsed, defaultLayout }) => (
  <div className={styles.logoWrapper}>
    {defaultLayout
      ? (
        <div>
          <img
            className={classNames(styles.logo, { [styles.logoCollapsed]: collapsed })}
            src={src}
            alt='logo'
            height={52}
          />
        </div>
      )
      : (
        <Link to='/'>
          <img
            className={classNames(styles.logo, { [styles.logoCollapsed]: collapsed })}
            src={src}
            alt='logo'
            height={88}
          />
        </Link>
      )}

  </div>
);

Logo.propTypes = {
  src: PropTypes.string,
  collapsed: PropTypes.bool,
  defaultLayout: PropTypes.bool,
};

Logo.defaultProps = {
  src: '/static/images/Logo_AWB.png',
  collapsed: false,
  defaultLayout: false,
};

export default Logo;
