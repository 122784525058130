import axios from 'axios';
import { BASE_URL } from './constants';

axios.defaults.withCredentials = true;

export const mainInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json', 'Accept-Language': 'de' },
});

export const refreshInstance = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json', 'Accept-Language': 'de' },
});

// eslint-disable-next-line consistent-return
mainInstance.interceptors.response.use((response) => response, async (error) => {
  const originalRequest = error.config;
  // eslint-disable-next-line no-underscore-dangle
  if (error.response.status === 401 && !originalRequest._retry) {
    // eslint-disable-next-line no-underscore-dangle
    originalRequest._retry = true;
    try {
      const refreshRequest = await refreshInstance.post('/auth/token/refresh/');
      if (refreshRequest.status === 200) {
        originalRequest.cookies = refreshRequest.cookies;
        return mainInstance(originalRequest);
      }
    } catch (err) {
      window.location.href = '/login';
    }
  } else {
    throw error;
  }
});
