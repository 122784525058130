export const BASE_URL = process.env.REACT_APP_API_URL;
export const COMPONENT_TYPES = {
  SLIDER: 'slider',
  BUTTON_GROUP: 'button_group',
  CARD_GROUP: 'card_group',
  RADIO_BUTTON_GROUP: 'radio_button_group',
  VORBEREITUNG: 'vorbereitung',
  AUSWERTUNG: 'auswertung',
  ASSESSMENT: 'assessment',
  BEWERTUNG: 'bewertung',
  CATEGORIES: 'categories',
  CARD: 'card',
  CONTACT: 'contact',
  QUESTIONNAIRE: 'questionnaire',
  ICP_ASSESSMENT: 'icp-assessment',
};

export const CATEGORIES = [{
  type: COMPONENT_TYPES.SLIDER,
  name: 'management',
  title: 'Performance Check \n Ex ante Selbsteinschätzung',
  text: 'Bekenntnis der Unternehmenleitung zur Exportcompliance',
  icon: '/static/images/1management.png',
},
{
  type: COMPONENT_TYPES.SLIDER,
  name: 'riskAnalytics',
  title: 'Performance Check \n Ex ante Selbsteinschätzung',
  text: 'Risikoanalyse',
  icon: '/static/images/2riskAnalitics.png',
},
{
  type: COMPONENT_TYPES.SLIDER,
  name: 'diagram',
  title: 'Performance Check \n Ex ante Selbsteinschätzung',
  text: 'Aufbauorganisation, Zuständigkeiten und Ressourcen',
  icon: '/static/images/3diagram.png',
},
{
  type: COMPONENT_TYPES.SLIDER,
  name: 'work',
  title: 'Performance Check \n Ex ante Selbsteinschätzung',
  text: 'Schulung und Sensibilisierung',
  icon: '/static/images/4work.png',
},
{
  type: COMPONENT_TYPES.SLIDER,
  name: 'structure',
  title: 'Performance Check \n Ex ante Selbsteinschätzung',
  text: 'Ablauforganistion',
  icon: '/static/images/5structure.png',
},
{
  type: COMPONENT_TYPES.SLIDER,
  name: 'document',
  title: 'Performance Check \n Ex ante Selbsteinschätzung',
  text: 'Führen von Aufzeichnungen und Dokumentation',
  icon: '/static/images/6document.png',
},
{
  type: COMPONENT_TYPES.SLIDER,
  name: 'security',
  title: 'Performance Check \n Ex ante Selbsteinschätzung',
  text: 'Physische Sicherheit und Informationssicherheit',
  icon: '/static/images/7security.png',
},
{
  type: COMPONENT_TYPES.SLIDER,
  name: 'documents',
  title: 'Performance Check \n Ex ante Selbsteinschätzung',
  text: 'Audits, Korrekturmaßnahmen und Umgang mit',
  icon: '/static/images/8documents.png',
},
{
  type: COMPONENT_TYPES.SLIDER,
  name: 'checklist',
  title: 'Performance Check \n Ex ante Selbsteinschätzung',
  text: 'Compliance Handbuch',
  icon: '/static/images/9checklist.png',
}];

export const CREATE_ASSESSMENT_CONFIG = [
  {
    type: COMPONENT_TYPES.RADIO_BUTTON_GROUP,
    name: 'assessment_type',
    title: 'Auswahl der Assessment-Form',
    children: [
      {
        value: 'DEEP_DIVE',
        img: '/static/images/statusanalyse.png',
        alt: 'statusanalyse',
        text: 'Statusanalyse',
        children: [
          {
            type: COMPONENT_TYPES.RADIO_BUTTON_GROUP,
            title: 'Statusanalyse',
            name: 'has_evaluation',
            children: [{ text: 'Mit Bewertung', value: true }, { text: 'Ohne Bewertung', value: false }],
          },
          {
            type: COMPONENT_TYPES.RADIO_BUTTON_GROUP,
            title: '',
            name: 'evaluator_type',
            children: [{ text: 'SAM', value: 'SAM' }, { text: 'AWB', value: 'AWB' }],
          },
          {
            type: COMPONENT_TYPES.RADIO_BUTTON_GROUP,
            title: '',
            name: 'has_preassessment',
            children: [{ text: 'Ja', value: true }, { text: 'Nein', value: false }],
          },
        ],
      },
      {
        value: 'PERFORMANCE_CHECK',
        text: 'Performance Check',
        img: '/static/images/performance.png',
        alt: 'performance check',
        children: [
          {
            type: COMPONENT_TYPES.RADIO_BUTTON_GROUP,
            title: 'Performance Check \n Ex ante Selbsteinschätzung',
            name: 'has_preassessment',
            children: [{ text: 'Ja', value: true }, { text: 'Nein', value: false }],
          },
        ],
      },
    ],
  },
];

export const WIZARD_CONFIG = {
  ADMIN: [
    {
      title: 'Vorbereitung',
      type: 'vorbereitung',
      tabs: [
        { text: 'Bewerter zuweisen', type: 'categories', data: [] },
        {
          text: 'Zeitrahmen festlegen',
          type: 'card',
          data: [{ title: 'Geplanter Abschluss des Assessments:', type: 'datePicker' },
            {
              title: 'Erinnerungsfunktion',
              type: 'checkbox',
              values: [
                { name: 'keine' },
                { name: '1 Woche vor geplantem Abschluss' },
                { name: '3 Tage vor geplantem Abschluss' },
                { name: '1 Tag vor geplantem Abschluss' }],
            }],
        }],
    }, {
      title: 'Assessment', type: 'assessment',
    },
    {
      title: 'Bewertung', type: 'bewertung',
    },
    {
      title: 'Auswertung', type: 'auswertung',
    }],
  SAM: [
    {
      title: 'Vorbereitung',
      type: 'vorbereitung',
      tabs: [
        { text: 'Bewerter zuweisen', type: 'categories', data: [] },
        {
          text: 'Zeitrahmen festlegen',
          type: 'card',
          data: [{ title: 'Geplanter Abschluss des Assessments:', type: 'datePicker' },
            {
              title: 'Erinnerungsfunktion',
              type: 'checkbox',
              values: [
                { name: 'keine' },
                { name: '1 Woche vor geplantem Abschluss' },
                { name: '3 Tage vor geplantem Abschluss' },
                { name: '1 Tag vor geplantem Abschluss' }],
            }],
        }],
    }, {
      title: 'Assessment', type: 'assessment',
    },
    {
      title: 'Bewertung', type: 'bewertung',
    },
    {
      title: 'Auswertung', type: 'auswertung',
    }],
};

export const CURRENT_STEP_WITH_WEIGHTING = {
  INITIALIZED: 0,
  STARTED: 1,
  WEIGHTING: 2,
  ENDED: 3,
};

export const CURRENT_STEP_WITHOUT_WEIGHTING = {
  INITIALIZED: 0,
  STARTED: 1,
  ENDED: 2,
};

export const ALPHABET = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
  5: 'F',
  6: 'G',
  7: 'H',
  8: 'I',
  9: 'J',
  10: 'K',
  11: 'L',
  12: 'M',
  13: 'N',
  14: 'O',
  15: 'P',
  16: 'Q',
  17: 'R',
  18: 'S',
  19: 'T',
  20: 'U',
  21: 'V',
  22: 'W',
  23: 'X',
  24: 'Y',
  25: 'Z',
};

export const TITLES = {
  DEEP_DIVE: 'Statusanalyse',
  PERFORMANCE_CHECK: 'Performance Check',
};

export const ANSWER_TYPES = ['TEXT', 'NUMERIC', 'BOOLEAN', 'GRADING_CHOICE'];

export const TOMORROW = new Date(new Date().setDate(new Date().getDate() + 1));

export const KPI = {
  title: {
    '?highlight': 'Highlights',
    '?weakness': 'Schwachstellen',
    '?discrepancy': 'Diskrepanzen',
  },
  search: {
    '?highlight': 'highlight',
    '?weakness': 'weakness',
    '?discrepancy': 'discrepancy',
  },
};
