import PropTypes from 'prop-types';
import { useEffect } from 'react';

import {
  Form, Input, Button, Checkbox, Row, Col, Card,
} from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from '../index.module.scss';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const LoginForm = ({
  onSubmit, formRef, submitting, initialValues,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    formRef.current = form;
  }, [formRef, form]);

  return (
    <>
      <Form
        {...formItemLayout}
        initialValues={{
          ...initialValues,
          remember: true,
        }}
        className={styles.authForm}
        onFinish={onSubmit}
        form={form}
        layout='vertical'
      >
        <Card title='Einloggen' bordered level={4} className={styles.title}>
          <Row gutter={[16, 16]} className={styles.row}>
            <Col span={24}>
              <Form.Item
                name='email'
                style={{ textAlign: 'left' }}
                className={styles.formItemAlignment}
                rules={[{ required: true, message: 'Bitte geben Sie ihre E-Mail-Adresse ein!' }]}
              >
                <Input
                  placeholder='Email'
                  className={styles.input}
                  prefix={<UserOutlined className={styles.icon} />}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name='password'
                rules={[{ required: true, message: 'Ein Passwort ist erforderlich!' }]}
                style={{ textAlign: 'left' }}
                className={styles.formItemAlignment}
              >
                <Input.Password
                  className={styles.input}
                  placeholder='Passwort'
                  prefix={<LockOutlined className={styles.icon} />}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row justify='space-between' align='middle'>
                <Form.Item name='remember' valuePropName='checked' style={{ float: 'left' }}>
                  <Checkbox id='checkBox'>Erinnere dich an mich</Checkbox>
                </Form.Item>
                <Form.Item style={{ float: 'right' }}>
                  <Link to='/reset-password' id='forgotPasswordLink'>Passwort vergessen</Link>
                </Form.Item>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item
                className={styles.formItemAlignment}
                style={{ textAlign: 'center' }}
              >
                <Button
                  id='loginButton'
                  type='primary'
                  htmlType='submit'
                  className={styles.buttonAlign}
                  loading={submitting}
                >
                  Einloggen
                </Button>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                className={styles.formItemAlignment}
                style={{ textAlign: 'center' }}
              >
                Oder&nbsp;&nbsp;
                <Link to='/register' id='registerLink'>jetzt registrieren!</Link>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  formRef: PropTypes.object,
  initialValues: PropTypes.object,
};

LoginForm.defaultProps = {
  onSubmit: () => {},
  submitting: false,
  formRef: { current: null },
  initialValues: {},
};

export default LoginForm;
