import {
  CHANGE_GLOBAL_LOADING,
  ADD_NEW_QUESTION,
  CLEAR_CUSTOMER_ASSESSMENTS,
  CREATE_ASSESSMENT,
  CREATE_ASSESSMENT_USER,
  DELETE_ASSESSMENT_USER,
  DELETE_QUESTION,
  DELETE_TEMPLATES,
  FETCH_ASSESSMENT_USERS,
  FETCH_ASSESSMENTS,
  FETCH_NEW_ASSESSMENT,
  FETCH_STATUS_ASSESSMENT,
  FETCH_USER_ASSESSMENT,
  GET_CUSTOMER_TEMPLATES,
  GET_TEMPLATES,
  MODIFY_TEMPLATES,
  RESET_FORM_DATA,
  RESET_NEW_ASSESSMENT,
  RESET_STATUS_ASSESSMENT,
  RESET_USER_ASSESSMENT_STATUS,
  SET_DEFAULT_ASSESSMENT_STATE,
  SET_FORM_DATA,
  SET_USER_ASSESSMENTS,
  UPDATE_ALL_TEMPLATES,
  UPDATE_ASSESSMENT,
  UPDATE_ASSESSMENT_QUESTION,
  UPDATE_ASSESSMENT_USERS,
  UPDATE_COMPANY_TEMPLATE,
  UPDATE_QUESTION,
  UPDATE_TEMPLATE,
  UPDATE_ASSESSMENT_VERSION,
  CREATE_ASSESSMENT_VERSION_WITH_FILE,
  UPDATE_USER_ASSESSMENTS, FETCH_ASSESSMENT_PROGRESS_SAM_ADMIN, FETCH_ALL_ACCOUNTS,
  FETCH_ASSESSMENT_REPORT,
  FETCH_ASSESSMENT_KPI, FETCH_QUESTIONS_REPORT,
} from './types';

export const fetchQuestionsReport = (slug) => ({
  type: FETCH_QUESTIONS_REPORT,
  request: {
    method: 'GET',
    url: `/assessments/${slug}/question-report/`,
  },
});
export const fetchAssessmentKPI = (slug, search) => {
  const params = search ? { kpi: search } : null;
  return {
    type: FETCH_ASSESSMENT_KPI,
    request: {
      method: 'GET',
      url: `/assessments/${slug}/kpi/`,
      params,
    },
  };
};
export const fetchAssessmentReport = (slug) => ({
  type: FETCH_ASSESSMENT_REPORT,
  request: {
    method: 'GET',
    url: `/assessments/${slug}/category-reports/`,
  },
});
export const fetchAllAccounts = () => ({
  type: FETCH_ALL_ACCOUNTS,
  request: {
    method: 'GET',
    url: 'accounts/',
  },
});
export const updateCompanyTemplate = (companySlug) => ({
  type: UPDATE_COMPANY_TEMPLATE,
  request: {
    method: 'GET',
    url: 'assessments/templates/update/',
    params: { company: companySlug },
  },
});

export const fetchUserAssessment = ({ assessmentSlug, userSlug }) => ({
  type: FETCH_USER_ASSESSMENT,
  request: {
    method: 'GET',
    url: `assessments/${assessmentSlug}/status/${userSlug}/`,
  },
});

export const fetchAssessmentProgressAdminSam = (assessmentSlug, self) => ({
  type: FETCH_ASSESSMENT_PROGRESS_SAM_ADMIN,
  request: {
    method: 'GET',
    url: `assessments/${assessmentSlug}/status/`,
    params: { self },
  },
});

export const fetchStatusAssessment = (assessmentSlug) => ({
  type: FETCH_STATUS_ASSESSMENT,
  request: {
    method: 'GET',
    url: `assessments/${assessmentSlug}/status/`,
  },
});

export const fetchAssessments = () => ({
  type: FETCH_ASSESSMENTS,
  request: {
    method: 'GET',
    url: 'assessments/',
  },
});

export const fetchNewAssessment = (slug) => ({
  type: FETCH_NEW_ASSESSMENT,
  request: {
    method: 'GET',
    url: `assessments/${slug}/`,
  },
});

export const createAssessment = (data) => ({
  type: CREATE_ASSESSMENT,
  request: {
    method: 'POST',
    url: 'assessments/',
    data,
  },
});

export const fetchAssessmentConfig = () => ({
  type: GET_TEMPLATES,
  request: {
    method: 'GET',
    url: 'assessments/templates/',
  },
});

export const fetchAssessmentCustomerConfig = (slug) => ({
  type: GET_CUSTOMER_TEMPLATES,
  request: {
    method: 'GET',
    url: 'assessments/templates/',
    params: { company: slug },
  },
});

export const fetchAssessmentUsers = async (slug) => ({
  type: FETCH_ASSESSMENT_USERS,
  request: {
    method: 'GET',
    url: `assessments/${slug}/users/`,
  },
});

export const createAssessmentUser = ({ slug, ...rest }) => ({
  type: CREATE_ASSESSMENT_USER,
  request: {
    method: 'POST',
    url: `assessments/${slug}/users/`,
    data: rest,
  },
});

export const updateAssessmentUsers = ({ slug, assessmentUser, ...rest }) => ({
  type: UPDATE_ASSESSMENT_USERS,
  request: {
    method: 'PATCH',
    url: `assessments/${slug}/users/`,
    data: { category: rest.category, assessment: slug, user: assessmentUser },
  },
});

export const deleteAssessmentUser = ({ slug, userSlug }) => ({
  type: DELETE_ASSESSMENT_USER,
  request: {
    method: 'DELETE',
    url: `assessments/${slug}/users/${userSlug}/`,
  },
});

export const createAssessmentVersionWithFile = ({ file, slug, ...rest }) => {
  const formData = new FormData();
  formData.append('file', file);
  Object.keys(rest).forEach((key) => {
    formData.append(key, rest[key]);
  });
  return {
    type: CREATE_ASSESSMENT_VERSION_WITH_FILE,
    request: {
      method: 'POST',
      url: `assessments/${slug}/versions/`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    },
  };
};

export const updateAssessmentVersion = ({
  file, slug, fileSlug, ...rest
}) => {
  const formData = new FormData();
  formData.append('file', file);
  Object.keys(rest).forEach((key) => {
    formData.append(key, rest[key]);
  });
  return {
    type: UPDATE_ASSESSMENT_VERSION,
    request: {
      method: 'PATCH',
      url: `assessments/${slug}/versions/`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: { assessment_file: fileSlug },
      data: formData,
    },
  };
};

export const updateAssessment = ({ slug, ...rest }) => ({
  type: UPDATE_ASSESSMENT,
  request: {
    method: 'PATCH',
    url: `assessments/${slug}/`,
    data: rest,
  },
});

export const updateTemplate = (data) => ({
  type: UPDATE_TEMPLATE,
  request: {
    method: 'PUT',
    url: 'assessments/templates/',
    data,
  },
});

export const addNewQuestion = (data) => ({
  type: ADD_NEW_QUESTION,
  request: {
    method: 'POST',
    url: 'questions/',
    data,
  },
});

export const deleteQuestion = ({ slug }) => ({
  type: DELETE_QUESTION,
  request: {
    method: 'DELETE',
    url: `questions/${slug}/`,
  },
});

export const updateQuestion = ({ slug, ...rest }) => ({
  type: UPDATE_QUESTION,
  request: {
    method: 'PATCH',
    url: `questions/${slug}/`,
    data: rest,
  },
});

export const modifyTemplates = (data) => ({
  type: MODIFY_TEMPLATES,
  payload: data,
});

export const deleteTemplates = () => ({
  type: DELETE_TEMPLATES,
});
export const updateAllTemplates = (data) => ({
  type: UPDATE_ALL_TEMPLATES,
  data,
});
export const updateTemplateQuestion = (data) => ({
  type: UPDATE_ASSESSMENT_QUESTION,
  data,
});
export const setFormData = (data) => ({
  type: SET_FORM_DATA,
  data,
});
export const resetForm = () => ({
  type: RESET_FORM_DATA,
});
export const clearCustomerAssessment = () => ({
  type: CLEAR_CUSTOMER_ASSESSMENTS,
});
export const setDefaultAssessmentState = () => ({
  type: SET_DEFAULT_ASSESSMENT_STATE,
});
export const setUserAssessments = (data) => ({
  type: SET_USER_ASSESSMENTS,
  data,
});

export const updateUserAssessments = (data) => ({
  type: UPDATE_USER_ASSESSMENTS,
  data,
});

export const resetNewAssessment = async () => ({
  type: RESET_NEW_ASSESSMENT,
});

export const changeGlobalLoading = (data) => ({
  type: CHANGE_GLOBAL_LOADING,
  data,
});

export const resetStatusAssessment = async () => ({
  type: RESET_STATUS_ASSESSMENT,
});

export const resetUserAssessmentStatus = async () => ({
  type: RESET_USER_ASSESSMENT_STATUS,
});
