import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Form, Input, Row, Col, Card, Button, AutoComplete, Tag, message,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { StepBackwardOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import styles from '../index.module.scss';
import { updateCompany } from '../../../store/company/actions';
import { getUsers } from '../../../store/auth/actions';
import { usersSelector } from '../../../store/auth/selectors';

const { Item } = Form;

const Contact = ({ company }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [managers, setManagers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const users = useSelector(usersSelector);
  const { customerSlug } = useParams();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue({
      name: company?.name || '',
      contact_name: company?.contact_name || '',
      contact_surname: company?.contact_surname || '',
      contact_email: company?.contact_email || '',
      street: company?.address?.street || '',
      postal_code: company?.address?.postal_code || '',
      number: parseInt(company?.address?.number, 10) || '',
      city: company?.address?.city || '',
      country: company?.address?.country || '',
    });
    if (company) {
      setManagers(company.managers.map((el) => el.email));
      setEmployees(company.employees.map((el) => el.email));
    }
  }, [company, form]);

  const addManager = useCallback((manager) => {
    if (!managers.includes(manager)) {
      setManagers([...managers, manager]);
    }
    form.resetFields(['managers']);
  }, [form, managers]);

  const addEmployees = useCallback((employee) => {
    if (!employees.includes(employee)) {
      setEmployees([...employees, employee]);
    }
    form.resetFields(['employees']);
  }, [employees, form]);

  const onSubmit = async () => {
    try {
      const formData = await form.validateFields();
      dispatch(updateCompany(customerSlug, {
        name: formData.name,
        contact_name: formData.contact_name,
        contact_surname: formData.contact_surname,
        contact_email: formData.contact_email,
        employees,
        managers,
        address: {
          street: formData.street,
          number: formData.number,
          postal_code: formData.postal_code,
          city: formData.city,
          country: formData.country,
        },
      }))
        .then((res) => {
          if (res.error) {
            return message.error('Serverfehler!');
          }
          return message.success('Die Firmendaten wurden aktualisiert.');
        });
    } catch (e) {
      message.error('validation error');
    }
  };

  return (
    <div>
      <Col className={styles.cardContact}>
        <Form
          form={form}
          layout='vertical'
          className={styles.formContact}
        >
          <Row justify='space-between' gutter={[16, 16]}>
            <Col span={10} style={{ margin: 'auto' }}>
              <Card title='Firmendetails' className={styles.cardColumn}>
                <Item
                  name='name'
                  label='Firma'
                  rules={[
                    {
                      required: true,
                      message: 'Dieses Feld wird benötigt.',
                    },
                  ]}
                >
                  <Input placeholder='Name der Firma' />
                </Item>
                <div>
                  <Item
                    style={{ marginBottom: '5px' }}
                    name='managers'
                    label='Self-assessment Manager (SAMs)'
                  >
                    <AutoComplete
                      style={{
                        width: 200,
                      }}
                      options={users.filter((item) => item?.role === 'SAM').map((el) => {
                        if (!managers.includes(el.email)) {
                          return { value: el.email };
                        }
                        return null;
                      })
                        .filter((el) => el !== null)}
                      placeholder='SAMs hinzufügen'
                      filterOption={(inputValue, option) => option.value.toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1}
                      onSelect={addManager}
                      suffixIcon={<StepBackwardOutlined />}
                    />
                  </Item>
                  <div style={{ marginBottom: '24px' }}>
                    {managers.map((email) => (
                      <Tag
                        key={email}
                        closable
                        onClose={(e) => {
                          e.preventDefault();
                          setManagers(managers.filter((el) => el !== email));
                        }}
                      >
                        {email}
                      </Tag>
                    ))}
                  </div>
                  <div>
                    <Item
                      style={{ marginBottom: '5px' }}
                      name='employees'
                      label='Mitarbeiter'
                    >
                      <AutoComplete
                        style={{
                          width: 200,
                        }}
                        options={users.map((el) => {
                          if (!employees.includes(el.email)) {
                            return { value: el.email };
                          }
                          return null;
                        })
                          .filter((el) => el !== null)}
                        placeholder='Mitarbeiter hinzufügen'
                        filterOption={(inputValue, option) => option.value.toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1}
                        onSelect={addEmployees}
                      />
                    </Item>
                    <div>
                      {employees.map((email) => (
                        <Tag
                          key={email}
                          closable
                          onClose={(e) => {
                            e.preventDefault();
                            setEmployees(employees.filter((el) => el !== email));
                          }}
                        >
                          {email}
                        </Tag>
                      ))}
                    </div>
                  </div>
                </div>
              </Card>
              <Card title='Kontakt Person' className={styles.cardColumn}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Item
                      name='contact_name'
                      label='Name'
                      className={styles.formInput}
                    >
                      <Input placeholder='Name' />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item
                      name='contact_surname'
                      label='Nachname'
                      className={styles.formInput}
                    >
                      <Input placeholder='Muster' />
                    </Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Item
                      name='contact_email'
                      label='E-mail'
                      rules={[
                        {
                          type: 'email',
                          message: 'Die E-Mail ist ungültig.',
                        },
                      ]}
                    >
                      <Input placeholder='max.muster@gmail.com' />
                    </Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={10} style={{ margin: '0 auto' }}>
              <Card title='Adresse'>
                <Row gutter={[16, 16]}>
                  <Col span={16}>
                    <Item
                      name='street'
                      label='Strasse'
                      rules={[
                        {
                          required: true,
                          message: 'Dieses Feld wird benötigt.',
                        },
                      ]}
                    >
                      <Input placeholder='Strasse' />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      name='number'
                      label='Hausnummer'
                      rules={[
                        {
                          required: true,
                          message: 'Das ist ein Pflichtfeld!',
                        },
                      ]}
                    >
                      <Input placeholder='Hausnummer' />
                    </Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Item
                      name='postal_code'
                      label='Postleitzahl'
                      rules={[
                        {
                          required: true,
                          message: 'Das ist ein Pflichtfeld!',
                        },
                      ]}
                    >
                      <Input placeholder='Postleitzahl' />
                    </Item>
                  </Col>
                  <Col span={16}>
                    <Item
                      name='city'
                      label='Stadt'
                      rules={[
                        {
                          required: true,
                          message: 'Das ist ein Pflichtfeld!',
                        },
                      ]}
                    >
                      <Input placeholder='Stadt' />
                    </Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Item
                      name='country'
                      label='Land'
                      rules={[
                        {
                          required: true,
                          message: 'Das ist ein Pflichtfeld!',
                        },
                      ]}
                    >
                      <Input placeholder='Land' />
                    </Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
        <Row justify='end' style={{ marginTop: '15px', textAlign: 'center' }}>
          <Button
            type='primary'
            onClick={onSubmit}
            size='large'
            data-cy='updateData'
          >
            Speichern
          </Button>
        </Row>
      </Col>
    </div>
  );
};

Contact.propTypes = {
  company: PropTypes.object,
};

Contact.defaultProps = {
  company: null,
};

export default Contact;
