import PropTypes from 'prop-types';

import {
  Button, Col, Form, Input, Modal, Row, Tag, message,
} from 'antd';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';

import { addNewCompany } from '../../../../store/company/actions';

import styles from './index.module.scss';

const { Item } = Form;

const NewCompanyModal = ({ isVisible, onCancel }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [managers, setManagers] = useState([]);
  const [employees, setEmployees] = useState([]);

  const handleSubmit = async () => {
    const formData = await form.validateFields();
    dispatch(addNewCompany({
      name: formData.name,
      employees,
      managers,
      address: {
        street: formData.street,
        number: formData.number,
        postal_code: formData.postalCode,
        city: formData.city,
        country: formData.country,
      },
    }))
      .then((res) => {
        if (res.error) {
          return message.error('General server error!');
        }
        setManagers([]);
        setEmployees([]);
        form.resetFields();
        onCancel(form, 'company');
        return message.success('The company has been added.');
      });
  };

  const addManager = async () => {
    try {
      await form.validateFields(['manager']);
      const manager = form.getFieldValue('manager');
      if (!managers.includes(manager)) {
        setManagers([...managers, manager]);
        form.resetFields(['manager']);
      }
    } catch (e) {
      message.error('Validation error');
    }
  };

  const addEmployees = async () => {
    try {
      await form.validateFields(['employee']);
      const employee = form.getFieldValue('employee');
      if (!employees.includes(employee)) {
        setEmployees([...employees, employee]);
        form.resetFields(['employee']);
      }
    } catch (e) {
      message.error('Validation error');
    }
  };

  const handleClose = () => {
    setManagers([]);
    setEmployees([]);
    onCancel(form, 'company');
  };

  return (
    <Modal
      centered
      closable={false}
      visible={isVisible}
      title='Erstellen Sie ein Unternehmen'
      footer={null}
      onCancel={handleClose}
      width={900}
    >
      <Form
        form={form}
        initialValues={{
          name: '', street: '', number: '', postalCode: '', city: '', country: '', manager: '', employee: '',
        }}
        onFinish={handleSubmit}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        layout='vertical'
        className={styles.form}
      >
        <Row justify='space-between'>
          <Col span={11}>
            <Item name='name' label='Firmenname' rules={[{ required: true, message: 'This field is required' }]}>
              <Input placeholder='Name der Firma' autoFocus />
            </Item>
          </Col>
          <Col span={11}>
            <Row justify='space-between'>
              <Col span={16}>
                <Item name='street' label='Strasse' rules={[{ required: true, message: 'This field is required' }]}>
                  <Input placeholder='Strasse' autoFocus />
                </Item>
              </Col>
              <Col span={7}>
                <Item name='number' label='Hausnummer' rules={[{ required: true, message: 'This field is required' }]}>
                  <Input placeholder='Hausnummer' autoFocus />
                </Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify='space-between' className={classNames({ [styles.needsPadding]: managers.length > 0 })}>
          <Col span={11}>
            <Item
              name='manager'
              label='SAM'
              rules={[{
                type: 'email',
                message: 'Die Eingabe ist keine gültige E-Mail!',
              }]}
            >
              <Input
                placeholder='E-mail Adresse eingeben und Enter'
                autoFocus
                onPressEnter={addManager}
              />
            </Item>
            {managers.map((tag) => (
              <Tag
                key={tag}
                closable
                onClose={(e) => {
                  e.preventDefault();
                  setManagers(managers.filter((el) => el !== tag));
                }}
              >
                {tag}
              </Tag>
            ))}
          </Col>
          <Col span={11}>
            <Row justify='space-between'>
              <Col span={7}>
                <Item
                  name='postalCode'
                  label='Postleitzahl'
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <Input placeholder='Postleitzahl' autoFocus />
                </Item>
              </Col>
              <Col span={16}>
                <Item name='city' label='Stadt' rules={[{ required: true, message: 'This field is required' }]}>
                  <Input placeholder='Stadt' autoFocus />
                </Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify='space-between'>
          <Col span={11}>
            <Item
              name='employee'
              label='Mitarbeiter'
              rules={[{
                type: 'email',
                message: 'Die Eingabe ist keine gültige E-Mail!',
              }]}
            >
              <Input
                name='employee'
                placeholder='E-mail Adresse eingeben und Enter'
                autoFocus
                onPressEnter={addEmployees}
              />
            </Item>
            {employees.map((tag) => (
              <Tag
                key={tag}
                closable
                onClose={(e) => {
                  e.preventDefault();
                  setEmployees(employees.filter((el) => el !== tag));
                }}
              >
                {tag}
              </Tag>
            ))}
          </Col>
          <Col span={11}>
            <Item name='country' label='Land' rules={[{ required: true, message: 'This field is required' }]}>
              <Input placeholder='Land' autoFocus />
            </Item>
          </Col>
        </Row>
        <Row className={styles.submit}>
          <Col>
            <Button htmlType='submit' type='primary' data-cy='createCompany'>Erstellen</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

NewCompanyModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default NewCompanyModal;
