import { Col, Row, message } from 'antd';

import { useCallback, useRef, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import RegisterForm from './components/RegisterForm';

import { register } from '../../store/auth/actions';

import styles from '../Login/index.module.scss';
import { responseHandler } from '../../utils/helpers';

const Register = () => {
  const [submitting, setSubmitting] = useState(false);
  const params = useParams();
  const history = useHistory();
  const form = useRef(null);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(async (formData) => {
    try {
      setSubmitting(true);
      const payload = { ...formData };
      if ('invite_id' in params) {
        payload.invite_id = params.invite_id;
      }
      dispatch(register(payload)).then((res) => responseHandler(res, () => {
        message.success('You have successfully signed up. Please log in.');
        history.push('/login');
      }));
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      message.error(e.response?.error || 'General server error');
    }
  }, [dispatch, params, history]);

  return (
    <Row justify='center' className={styles.container}>
      <Col span={6}>
        <RegisterForm onSubmit={handleSubmit} formRef={form} submitting={submitting} />
      </Col>
    </Row>
  );
};

export default Register;
