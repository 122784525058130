import PropTypes from 'prop-types';

import {
  Button, Card, Col, Divider, Form, Input, Radio, Row, Tooltip,
} from 'antd';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import {
  useEffect,
  useMemo, useState,
} from 'react';

import classNames from 'classnames';

import styles from '../../../../views/UserCategoryQuestions/index.module.scss';
import { setDefaultValuesAnswerForm } from '../../../../utils/helpers';

const { TextArea } = Input;
const { Item } = Form;
const { Group } = Radio;

const AnswerQuestion = ({
  question, next, back, isLast,
}) => {
  const [primary, setPrimary] = useState({ yes: false, no: false, none: false });
  const [isVisible, setVisible] = useState(false);

  const [form] = Form.useForm();

  const actions = [
    <Button
      icon={<LeftOutlined />}
      key='back'
      onClick={back}
      size='small'
    >
      Vorherige Frage
    </Button>,
    <Button icon={<RightOutlined />} key='next' type='default' size='small' onClick={next}>
      {!isLast ? 'Nächste Frage' : 'Zurück zu den Kategorien'}
    </Button>,
  ];

  const titleContent = () => (
    <Row justify='space-between'>
      {question?.title?.length >= 110
        ? (
          <Tooltip placement='top' title={question?.title}>
            {question?.title.slice(0, 110)}
            ...
          </Tooltip>
        )
        : <Col>{question?.title}</Col>}
      {question?.identifier && (
        <>
          <Col className={styles.container}>
            <span className={styles.questionIdentifier}>
              [
              {question.identifier}
              ]
            </span>
          </Col>
        </>
      )}
    </Row>
  );

  const answerType = useMemo(() => {
    if (question.type === 'BOOLEAN') {
      return (
        <Item name='answer'>
          <Row className={classNames(styles.booleanAnswer)}>
            <Col span={12}>
              <Button
                className={styles.noneEvents}
                type={primary.yes ? 'primary' : 'dashed'}
                size='large'
              >
                Ja
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className={styles.noneEvents}
                type={primary.no ? 'primary' : 'dashed'}
                size='large'
              >
                Nein
              </Button>
            </Col>
          </Row>
        </Item>
      );
    }
    if (question.type === 'TEXT') {
      return (
        <Item
          name='answer'
        >
          <TextArea className={styles.noneEvents} />
        </Item>
      );
    }
    if (question.type === 'MULTIPLE_CHOICE') {
      return (
        <Item
          name='answer'
          className={styles.noneEvents}
        >
          <Group style={{ width: '100%' }}>
            <Row>
              {(question?.logic?.answer_options || []).map((item, index) => (
                <Col key={index.toString()} span={24} className={styles.answerOption}>
                  <Radio value={item.option.value}>{ item.option.value }</Radio>
                </Col>
              ))}
            </Row>
          </Group>
        </Item>
      );
    }
    return (
      <Item name='answer' className={styles.noneEvents}>
        <Group>
          <Radio value='0'>0</Radio>
          <Radio value='5'>5</Radio>
          <Radio value='8'>8</Radio>
          <Radio value='10'>10</Radio>
        </Group>
      </Item>
    );
  }, [primary, question]);

  const renderFiles = (item, index) => (
    <div key={index.toString()}>
      <a href={item.file} target='_blank' rel='noreferrer'>{item.name}</a>
    </div>
  );

  useEffect(() => {
    if (Object.keys(question).length) {
      setDefaultValuesAnswerForm(question, setPrimary, setVisible, form);
    }
    return () => {
      setVisible(false);
      setPrimary({ yes: false, no: false, none: false });
    };
  }, [question, form]);

  return (
    <div className={styles.questionCard}>
      <Form form={form} layout='vertical'>
        <Card title={titleContent()} actions={actions}>
          {!!Object.keys(question).length && (
            <>
              <Row className={styles.questionContainer}>
                <Col className={styles.question}><p>{question.question}</p></Col>
                <Col className={styles.hint}>
                  <p>{question?.hint}</p>
                </Col>
              </Row>
              <Row justify='space-between'>
                <Col span={18}>{ answerType }</Col>
                <Col span={6} className={styles.answerNoneContainer}>
                  <Item name='none'>
                    <Button
                      className={classNames(styles.noneEvents, { [styles.hidden]: question.type === 'BOOLEAN' })}
                      type={primary.none ? 'primary' : ''}
                    >
                      keine Antwort möglich
                    </Button>
                  </Item>
                </Col>
              </Row>
            </>
          )}
          <Divider style={{ margin: 0 }} />
        </Card>
        {isVisible && (
          <Card title='Verbesserungsvorschlag' className={styles.reasonCard}>
            <Item name='suggestion'><TextArea className={styles.noneEvents} /></Item>
          </Card>
        ) }
        <Card title='Anmerkung' className={styles.reasonCard}>
          <Item name='reason'><TextArea className={styles.noneEvents} /></Item>
        </Card>
        {question.files && (
          <Card title='Datei Anhänge' className={styles.uploadFileCard}>
            { question.files.map(renderFiles)}
          </Card>
        )}
      </Form>
    </div>
  );
};

AnswerQuestion.propTypes = {
  question: PropTypes.object,
  isLast: PropTypes.bool.isRequired,
  back: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};

AnswerQuestion.defaultProps = {
  question: {},
};

export default AnswerQuestion;
