import {
  CREATE_ANSWER, DELETE_ANSWER, FETCH_ALL_ANSWERS, FETCH_ANSWER, UPDATE_ANSWER,
} from './types';

export const fetchAllAnswers = (assessmentSlug) => ({
  type: FETCH_ALL_ANSWERS,
  request: {
    method: 'GET',
    url: 'answers/',
    params: { assessment: assessmentSlug },
  },
});

export const fetchAnswer = (slug) => ({
  type: FETCH_ANSWER,
  request: {
    method: 'GET',
    url: `answers/${slug}/`,
  },
});

export const createAnswer = (payload) => {
  const { files, ...rest } = payload;

  if (files && files.length) {
    const formData = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      formData.append('files', files[i].originFileObj);
    }
    Object.keys(rest).forEach((key) => {
      formData.append(key, rest[key]);
    });
    return {
      type: CREATE_ANSWER,
      request: {
        method: 'POST',
        url: 'answers/',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      },
    };
  }
  return {
    type: CREATE_ANSWER,
    request: {
      method: 'POST',
      url: 'answers/',
      data: rest,
    },
  };
};

export const updateAnswer = ({ answerSlug, ...rest }) => {
  const { files, ...other } = rest;
  if (files && files.length) {
    const formData = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      formData.append('files', files[i].originFileObj);
    }
    Object.keys(other).forEach((key) => {
      formData.append(key, other[key]);
    });
    return {
      type: CREATE_ANSWER,
      request: {
        method: 'PATCH',
        url: `answers/${answerSlug}/`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      },
    };
  }
  return {
    type: UPDATE_ANSWER,
    request: {
      method: 'PATCH',
      url: `answers/${answerSlug}/`,
      data: other,
    },
  };
};

export const deleteAnswer = (answerSlug) => ({
  type: DELETE_ANSWER,
  request: {
    method: 'DELETE',
    url: `answers/${answerSlug}/`,
  },
});
