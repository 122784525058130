import PropTypes from 'prop-types';

import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Card, Col, PageHeader, Row, Button, Table, Input, message, Checkbox, Modal, Typography, Form, Upload,
} from 'antd';
import {
  MoreOutlined, PlusOutlined, EditOutlined, UploadOutlined,
} from '@ant-design/icons';

import classNames from 'classnames';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import styles from '../../../views/Questionnaire/index.module.scss';
import EditQuestion from '../EditQuestion/EditQuestion';
import {
  createAssessmentVersionWithFile,
  deleteQuestion, fetchAssessmentConfig,
  modifyTemplates, updateAllTemplates, updateAssessment, updateAssessmentVersion,
  updateQuestion,
  updateTemplateQuestion,
} from '../../../store/assessments/actions';
import { ANSWER_TYPES, TITLES } from '../../../utils/constants';
import { templatesSelector } from '../../../store/assessments/selectors';
import { findMatch, responseHandler, updateTemplatesPayload } from '../../../utils/helpers';

import SkipForm from './components/SkipForm';
import AddNewQuestion from '../../../views/Questionnaire/components/AddNewQuestion';

const { Item } = Form;

const DragHandle = sortableHandle(() => (
  <div className={styles.dragIcon} data-cy='dragAndDrop'>
    <MoreOutlined />
    <MoreOutlined />
  </div>
));

const QuestionnaireConfiguration = ({
  category, name, types, children, hideWeight, onChangeState,
}) => {
  const [version, setVersion] = useState({});
  const [isVisibleConfirmModal, setVisibleConfirmModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [payload, setPayload] = useState();
  const [updated, setUpdated] = useState(false);
  const [modal, setModal] = useState({ visible: false, data: { currentQuestion: { question: '' } } });
  const [generalModal, setGeneralModal] = useState({ visible: false });
  const [state, setState] = useState({ DEEP_DIVE: [], PERFORMANCE_CHECK: [] });
  const [ids, setIds] = useState({});
  const formRef = useRef(null);
  const logicRef = useRef(null);
  const skipFormRef = useRef(null);
  const templates = useSelector(templatesSelector);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChangeVisible = useCallback(() => {
    setVisible(false);
  }, []);

  const addNewQ = useCallback((type) => {
    const obj = types.find((item) => item.type === type);
    setPayload({
      ...payload, assessment_type: type, assessment: obj.slug, order: state[type].length + 1,
    });
    setVisible(true);
  }, [types, state, payload]);

  const handleChangeWeighting = useCallback((e, record) => {
    const number = (e.target.value.charAt(0) === '0' && e.target.value.length >= 2)
      ? e.target.value.slice(1)
      : e.target.value;
    dispatch(updateQuestion({
      slug: record.slug,
      logic: {
        ...record.logic,
        weight: e.target.value === ''
          ? 0
          : number,
      },
    })).then((res) => {
      if (res.error) {
        return message.error('Genereller Fehler!');
      }
      if (onChangeState) {
        onChangeState({ ...res.data, assessment_type: record.assessment_type });
      }
      dispatch(updateTemplateQuestion({ ...res.data, assessment_type: record.assessment_type }));
      return message.success('Änderungen wurden erfolgreich gespeichert');
    });
  }, [onChangeState, dispatch]);

  const handleChangeModal = useCallback((record) => {
    if (ANSWER_TYPES.includes(record.type)) {
      const result = [];
      const start = (state[record.assessment_type].findIndex((item) => item.slug === record.slug) + 1);
      const items = state[record.assessment_type].slice(start);
      for (let index = 0; index < items.length; index += 1) {
        if (
          items[index].type === 'BOOLEAN'
          && items[index + 1]?.type !== 'BOOLEAN'
        ) break;

        result.push(items[index]);
      }

      setModal({
        visible: true,
        data: {
          currentQuestion: {
            slug: record.slug,
            logic: record.logic,
            question: record.question,
            title: record.title,
            assessment_type: record.assessment_type,
          },
          lists: result,
        },
      });
    }
  }, [state]);

  // eslint-disable-next-line consistent-return
  const handleChangeVisibleQuestion = useCallback((e, record) => {
    const index = state[record.assessment_type].findIndex((item) => item.slug === record.slug);
    const sliced = state[record.assessment_type].slice(0, index).map(({ slug }) => slug);
    const skipped = [];

    sliced.forEach((slug) => {
      if (ids[record.assessment_type].includes(slug)) {
        const i = state[record.assessment_type].findIndex((item) => item.slug === slug);
        skipped.push(i + 1);
      }
    });

    if (skipped.length) {
      const current = Math.max(...skipped) + 1;
      if (current === record.index && !ids[record.assessment_type].includes(record.slug)) {
        return message.error('Die Frage ist ein Sprungziel und kann daher nicht ausgeblendet werden.');
      }
    }

    if (record.logic?.skip?.length) {
      return message.error('Die Frage initiiert einen Sprung und kann daher nicht ausgeblendet werden.');
    }
    setState((s) => ({
      ...s,
      [record.assessment_type]: state[record.assessment_type].map((item) => {
        if (item.index === record.index) {
          return {
            ...item,
            is_visible: !item.is_visible,
          };
        }
        return item;
      }),
    }));

    dispatch(updateQuestion({ slug: record.slug, is_visible: e.target.checked })).then((res) => {
      if (res.error) {
        return message.error('Genereller Fehler!');
      }
      if (onChangeState) {
        onChangeState({ ...res.data, assessment_type: record.assessment_type });
      }
      return message.success('Änderungen wurden erfolgreich gespeichert');
    });
  }, [onChangeState, dispatch, state, ids]);

  const handleChangeGeneralModal = useCallback((record) => {
    setGeneralModal((s) => ({
      ...s,
      data: { ...record, category },
      visible: true,
    }));
    logicRef.current = {
      data: { ...record, category },
    };
  }, [category]);

  const onCancelGeneralModal = () => {
    setGeneralModal({ visible: false });
  };
  const updateState = useCallback((updateData, field) => {
    dispatch(updateTemplateQuestion(updateData));
    if (field === 'generalModal') {
      setUpdated(false);
      logicRef.current = null;
      setGeneralModal({ visible: false });
    }
    if (field === 'skipModal') {
      setModal({ visible: false, data: { currentQuestion: { question: '' } } });
    }
    return message.success('Änderungen wurden erfolgreich gespeichert');
  }, [dispatch]);

  const onFinish = useCallback((data) => {
    const keys = Object.keys(data);
    const skip = [];
    keys.forEach((key) => {
      if (data[key]) {
        skip.push(key);
      }
    });
    const test = [];
    state[modal.data.currentQuestion.assessment_type].forEach((item) => {
      skip.forEach((slug) => {
        if (item.slug === slug) {
          test.push(item.slug);
        }
      });
    });
    const allIds = state[modal.data.currentQuestion.assessment_type].map(({ slug }) => slug);
    const indexes = findMatch(test, allIds);
    const max = Math.max(...indexes);
    let search = {};
    state[modal.data.currentQuestion.assessment_type].forEach((item, index) => {
      if ((max + 1) === index) {
        search = item;
      }
    });
    if (Object.keys(search).length) {
      dispatch(updateQuestion({
        slug: search.slug,
        is_visible: true,
      })).then((res) => {
        dispatch(updateTemplateQuestion({
          ...res.data,
          assessment_type: modal.data.currentQuestion.assessment_type,
        }));
      });
    }
    const v = !modal.data.currentQuestion.is_visible ? true : modal.data.currentQuestion.is_visible;
    dispatch(updateQuestion({
      slug: modal.data.currentQuestion.slug,
      is_visible: v,
      logic: { ...modal.data.currentQuestion.logic, skip },
    })).then((res) => responseHandler(res,
      () => updateState({ ...res.data, assessment_type: modal.data.currentQuestion.assessment_type },
        'skipModal')));
  }, [state, updateState, modal, dispatch]);

  const onCancel = useCallback(() => {
    setModal({ visible: false, data: { currentQuestion: { question: '' } } });
  }, []);

  // eslint-disable-next-line consistent-return
  const saveEditedQuestion = useCallback(async () => {
    try {
      const {
        hint, question, identifier, title,
      } = await formRef.current.validateFields();

      const data = {
        slug: generalModal.data.slug,
        hint: hint || '',
        question,
        identifier,
        title,
        logic: logicRef.current.data.logic,
      };

      dispatch(updateQuestion(data)).then((res) => responseHandler(res,
        () => updateState({ ...res.data, assessment_type: generalModal.data.assessment_type },
          'generalModal')));
    } catch (e) {
      setUpdated(false);
      setGeneralModal({ visible: false });
      return message.error('Validation error');
    }
  }, [updateState, dispatch, generalModal]);

  const onChange = useCallback((e, a) => {
    const options = logicRef.current.data.logic.answer_options.map((item) => {
      if (item.idx === a.idx) {
        return {
          ...item,
          option: { letter: a.option.letter, value: e.target.value },
        };
      }
      return item;
    });
    logicRef.current = {
      data: { ...logicRef.current.data, logic: { ...logicRef.current.data.logic, answer_options: options } },
    };
  }, []);

  const delQuestion = useCallback(() => {
    let needsUpdate = {};
    state[generalModal.data.assessment_type].forEach((item) => {
      if (item.logic?.skip?.length) {
        if (item.logic.skip.includes(generalModal.data.slug)) {
          const skip = item.logic.skip.filter((slug) => slug !== generalModal.data.slug);
          needsUpdate = { ...item, logic: { ...item.logic, skip } };
        }
      }
      return item;
    });
    dispatch(deleteQuestion({ slug: generalModal.data.slug })).then(async (res) => {
      if (res.error) {
        return message.error('Genereller Fehler!');
      }
      if (Object.keys(needsUpdate).length) {
        dispatch(updateQuestion({ slug: needsUpdate.slug, logic: needsUpdate.logic })).then((response) => {
          if (response.error) {
            message.success('Genereller Fehler!');
          }
          updateState({
            category: response.data.category,
            hint: response.data.hint,
            title: response.data.title,
            assessment_type: needsUpdate.assessment_type,
            slug: response.data.slug,
            question: response.data.question,
            is_visible: response.data.is_visible,
            logic: response.data.logic,
          }, 'generalModal');
        });
      }
      dispatch(modifyTemplates({
        assessment: generalModal.data.assessment,
        category: generalModal.data.category,
        slug: generalModal.data.slug,
      }));
      return setGeneralModal({ visible: false });
    });
  }, [updateState, dispatch, state, generalModal]);

  const findLastSkipQuestion = useCallback((question) => {
    const allIds = state[question.assessment_type].map(({ slug }) => (slug));
    const indexes = findMatch(question.logic.skip, allIds);
    if (indexes.length) {
      const lastQuestion = state[question.assessment_type].find((i) => i.order === Math.max(...indexes) + 2);
      return lastQuestion?.identifier || 'Ende';
    }
    return null;
  }, [state]);

  const columns = useMemo(() => (children
    ? [
      {
        title: 'Identifier',
        dataIndex: 'identifier',
        key: 'identifier',
        render: (identifier) => (
          <div className={styles.sequentialNumber}>{identifier}</div>
        ),
      },
      {
        title: 'Frage/Aussage',
        dataIndex: 'question',
        key: 'question',
        render: (question, record) => (
          <div
            role='presentation'
          >
            <Typography.Text
              className={ids[record.name]?.includes(record.id) ? styles.skip : undefined}
            >
              {record.title}
            </Typography.Text>
          </div>

        ),
      },
      {
        title: 'Gewichtung',
        dataIndex: 'logic.weight',
        key: 'logic.weight',
        render: (logic, record) => (
          <>
            <Input
              data-cy='weight'
              style={{ maxWidth: '100px' }}
              step='1'
              min='0'
              type='number'
              value={record?.logic.weight}
              onChange={(e) => handleChangeWeighting(e, record)}
              onKeyDown={(e) => (['+', '-', 'e', 'E', '.'].includes(e.key)) && e.preventDefault()}
            />
          </>
        ),
      },
      {
        title: 'Sichtbar',
        dataIndex: 'is_visible',
        key: 'is_visible',
        render: (v, record) => (
          <div className={styles.textCenter}>
            <Checkbox
              checked={record.is_visible}
              onChange={(e) => handleChangeVisibleQuestion(e, record)}
            />
          </div>
        ),
      },
    ]
    : [
      {
        title: 'Identifier',
        dataIndex: 'identifier',
        key: 'identifier',
        render: (identifier) => (
          <div className={styles.dragContainer}>
            <DragHandle />
            <div
              role='presentation'
              className={styles.questionIdentifier}
            >
              {identifier}
            </div>
          </div>
        ),
      },
      {
        title: 'Frage/Aussage',
        dataIndex: 'question',
        key: 'question',
        render: (question, record) => (
          <div
            role='presentation'
            onClick={() => handleChangeGeneralModal(record)}
            className={styles.question}
          >
            <Typography.Text
              className={classNames({ [styles.skip]: ids[record.assessment_type]?.includes(record.slug) })}
            >
              {record.title}
            </Typography.Text>
          </div>

        ),
      },
      {
        title: 'Gewichtung',
        dataIndex: 'logic.weight',
        key: 'logic.weight',
        render: (logic, record) => (
          <>
            <Input
              style={{ maxWidth: '100px' }}
              step='1'
              min='0'
              type='number'
              value={record?.logic.weight}
              onChange={(e) => handleChangeWeighting(e, record)}
              onKeyDown={(e) => (['+', '-', 'e', 'E', '.'].includes(e.key)) && e.preventDefault()}
            />
          </>
        ),
      },
      {
        title: 'Sprungziel',
        dataIndex: 'logic.skip',
        key: 'logic.skip',
        render: (logic, record) => (
          <>
            {(record.type === 'BOOLEAN' && record?.logic.skip) && (
              <div role='presentation' className={styles.textCenter} onClick={() => handleChangeModal(record)}>
                <span>
                  {findLastSkipQuestion(record) || <EditOutlined />}
                </span>
              </div>
            )}
          </>
        ),
      },
    ]), [findLastSkipQuestion, handleChangeGeneralModal, children,
    handleChangeVisibleQuestion, ids, handleChangeModal, handleChangeWeighting]);

  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);

  // eslint-disable-next-line consistent-return
  const onSort = useCallback(async (type, oldIndex, newIndex) => {
    if (ids[type].length) {
      // eslint-disable-next-line max-len
      return message.error('Es müssen alle Sprünge in der Kategorie entfernt werden, bevor Fragen verschoben werden können.');
    }
    if (oldIndex !== newIndex && !ids[type].length) {
      const modifiedQuestions = arrayMoveImmutable([].concat(state[type]), oldIndex, newIndex)
        .map((item, index) => ({
          ...item,
          is_visible: true,
          index: String(index + 1),
          order: index + 1,
        }));
      const pld = updateTemplatesPayload(templates, modifiedQuestions, type, category);
      const original = state[type];
      setState((s) => ({ ...s, [type]: modifiedQuestions }));
      dispatch(updateAllTemplates(pld));
      const series = async () => {
        let results;
        for (let i = 0; i < modifiedQuestions.length; i += 1) {
          results = dispatch(updateQuestion(
            { slug: modifiedQuestions[i].slug, order: modifiedQuestions[i].order },
          ));
        }
        return results;
      };
      const result = await series();
      if (result.error) {
        const originalTemplate = updateTemplatesPayload(templates, original, type, category);
        responseHandler(result);
        setState((s) => ({ ...s, [type]: original }));
        dispatch(updateAllTemplates(originalTemplate));
      } else {
        return message.success('Änderungen wurden erfolgreich gespeichert');
      }
    }
  }, [category, dispatch, ids, state, templates]);

  // eslint-disable-next-line consistent-return,max-len
  const onSortEndPerformance = useCallback(({ oldIndex, newIndex }) => onSort('PERFORMANCE_CHECK', oldIndex, newIndex), [onSort]);

  // eslint-disable-next-line consistent-return,max-len
  const onSortEndStatusAnalyse = useCallback(({ oldIndex, newIndex }) => onSort('DEEP_DIVE', oldIndex, newIndex), [onSort]);

  const DraggableBodyRowStatusAnalyse = useCallback(({ ...restProps }) => {
    const index = state.DEEP_DIVE.findIndex((x) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  }, [state]);

  const DraggableBodyRowPerformanceCheck = useCallback(({ ...restProps }) => {
    const index = state.PERFORMANCE_CHECK.findIndex((x) => x.index === restProps['data-row-key']);
    return <SortableItem key={index} index={index} {...restProps} />;
  }, [state]);

  const DraggableContainerPerformance = useCallback((props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEndPerformance}
      {...props}
    />
  ), [onSortEndPerformance]);

  const DraggableContainerStatusAnalyse = useCallback((props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEndStatusAnalyse}
      {...props}
    />
  ), [onSortEndStatusAnalyse]);

  const containers = useMemo(() => ({
    PERFORMANCE_CHECK: DraggableContainerPerformance,
    DEEP_DIVE: DraggableContainerStatusAnalyse,
  }), [DraggableContainerPerformance, DraggableContainerStatusAnalyse]);

  const components = useMemo(() => ({
    PERFORMANCE_CHECK: DraggableBodyRowPerformanceCheck,
    DEEP_DIVE: DraggableBodyRowStatusAnalyse,
  }), [DraggableBodyRowPerformanceCheck, DraggableBodyRowStatusAnalyse]);

  const renderContent = useCallback((item, index) => (
    <Col span={children ? 11 : 12} key={index.toString()} data-cy={`questions-${item.type}`}>
      <Card title={TITLES[item.type]} className={styles.card}>
        <Row gutter={[16, 16]}>
          {!children
          && (
            <Col span={24} className={styles.actionButton}>
              <Button
                onClick={() => addNewQ(item.type)}
                type='primary'
                icon={<PlusOutlined />}
              >
                neue Frage
              </Button>
            </Col>
          )}
          <Col span={24}>
            <Table
              dataSource={state[item.type]}
              columns={Object.keys(hideWeight).length && !hideWeight[item.type]
                ? columns.filter((i) => i.key !== 'logic.weight')
                : columns}
              rowKey='index'
              pagination={false}
              components={{
                body: {
                  wrapper: containers[item.type],
                  row: components[item.type],
                },
              }}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  ), [hideWeight, containers, components, children, columns, state, addNewQ]);

  const titleContent = (
    <>
      <Row justify='end'>
        <Button
          style={{ position: 'absolute', top: '10px' }}
          type='primary'
          size='small'
          onClick={() => setVisibleConfirmModal(true)}
        >
          Neue Revision erstellen
        </Button>
      </Row>
      <Row justify='space-between'>
        <Col>Fragebogenkonfiguration</Col>
        <Col>{name || ''}</Col>

      </Row>
    </>
  );

  const skipFormInitialValues = useMemo(
    () => (ids?.[modal.data.currentQuestion.assessment_type] || [])
      .reduce((a, c) => Object.assign(a, { [c]: true }), {}),
    [ids, modal.data.currentQuestion.assessment_type],
  );

  const changeVersionTemplate = async () => {
    const o = {};
    const formData = form.getFieldsValue();

    Object.keys(version).forEach((key) => {
      if (formData?.file?.fileList?.length) {
        Object.assign(o, {
          [key]: (Number(version[key]) + 0.1).toFixed(1),
          file: formData.file.fileList[0].originFileObj,
        });
      } else {
        Object.assign(o, { [key]: (Number(version[key]) + 0.1).toFixed(1) });
      }
    });
    const keys = Object.keys(o).sort();

    if (formData?.file?.fileList?.length) {
      const performanceCheck = types.find((item) => item.slug === keys[0]);
      const deepDive = types.find((item) => item.slug === keys[1]);
      if (performanceCheck.versions.length) {
        const fileSlug = performanceCheck.versions[0].slug;
        await dispatch(updateAssessmentVersion({
          slug: keys[0], version: o[keys[0]], file: o[keys[2]], fileSlug,
        })).then((res) => {
          if (res.error) {
            responseHandler(res);
          }
        });
      } else {
        await dispatch(createAssessmentVersionWithFile({
          slug: keys[0],
          version: o[keys[0]],
          file: o[keys[2]],
        })).then((res) => {
          if (res.error) {
            responseHandler(res);
          }
        });
      }
      if (deepDive.versions.length) {
        const fileSlug = deepDive.versions[0].slug;
        await dispatch(updateAssessmentVersion({
          slug: keys[1], version: o[keys[1]], file: o[keys[2]], fileSlug,
        })).then((res) => {
          if (res.error) {
            responseHandler(res);
          } else {
            setVersion(o);
            setVisibleConfirmModal(false);
            form.resetFields();
            message.success('Fragebogenvorlage erfolgreich upgedated');
          }
        });
      } else {
        await dispatch(createAssessmentVersionWithFile({
          slug: keys[1],
          version: o[keys[1]],
          file: o[keys[2]],
        })).then((res) => {
          if (res.error) {
            responseHandler(res);
          } else {
            setVersion(o);
            setVisibleConfirmModal(false);
            form.resetFields();
            message.success('Fragebogenvorlage erfolgreich upgedated');
          }
        });
      }
      dispatch(fetchAssessmentConfig());
    } else {
      dispatch(updateAssessment({ slug: keys[0], version: o[keys[0]] }));
      dispatch(updateAssessment({ slug: keys[1], version: o[keys[1]] }))
        .then((res) => {
          if (res.error) {
            responseHandler(res);
          } else {
            setVersion(o);
            setVisibleConfirmModal(false);
            form.resetFields();
            message.success('Fragebogenvorlage erfolgreich upgedated');
          }
        });
    }
  };

  useEffect(() => {
    if (category && types.length) {
      types.forEach((t) => {
        setVersion((s) => ({ ...s, [t.slug]: t.version }));
      });
      setPayload({
        has_file_upload: true,
        type: 'BOOLEAN',
        answer_options: [],
        hint: '',
        category,
        order: 0,
        is_visible: true,
        logic: {},
        identifier: '',
      });
      types.forEach((item) => {
        const sorted = item.questions.sort((a, b) => (a.order > b.order ? 1 : -1));
        setState((s) => ({
          ...s,
          [item.type]: sorted.map((o, idx) => ({
            ...o,
            index: idx + 1,
          })),
        }));
        const idsToSkip = [];

        item.questions.forEach((i) => {
          if (i.logic.skip) {
            i.logic.skip.forEach((skipId) => idsToSkip.push(skipId));
          }
        });
        setIds((s) => ({
          ...s,
          [item.type]: idsToSkip,
        }));
      });
    }
  }, [category, types]);

  useEffect(() => {
    history.listen(() => visible && setVisible(false));
  }, [visible, history]);

  return (
    <div>
      {!children && <PageHeader title={titleContent} className={styles.pageHeader} /> }
      {!visible
        ? (
          <Row
            justify='center'
            className={classNames(styles.cardContainer,
              { [styles.containerProjectOverview]: children })}
          >
            <Row>
              {children}
              {types?.map(renderContent)}
            </Row>
            <Modal
              centered
              title='Neue Revision erstellen'
              visible={isVisibleConfirmModal}
              onOk={changeVersionTemplate}
              onCancel={() => {
                setVisibleConfirmModal(false);
                form.resetFields();
              }}
            >
              <span>
                Wollen Sie die aktuellen Änderungen speichern und
                eine neue Revision der Fragebogenvorlage erstellen?
              </span>
              <Row style={{ marginTop: '10px' }}>
                <Form form={form}>
                  <Item
                    name='file'
                    valuePropName='file'
                    style={{ marginBottom: 0 }}
                  >
                    <Upload
                      multiple={false}
                      maxCount={1}
                      accept='.pdf'
                      beforeUpload={() => false}
                    >
                      <Button icon={<UploadOutlined />}>Hochladen</Button>
                    </Upload>
                  </Item>
                </Form>
              </Row>
            </Modal>
            <Modal
              visible={modal.visible}
              centered
              title={modal.data.currentQuestion.title}
              onCancel={onCancel}
              footer={null}
              destroyOnClose
            >
              <SkipForm
                skipOptions={modal.data.lists}
                onFinish={onFinish}
                initialValues={skipFormInitialValues}
                formRef={skipFormRef}
              />
            </Modal>
            <EditQuestion
              updated={updated}
              formRef={formRef}
              deleteQuestion={delQuestion}
              data={generalModal}
              onCancel={onCancelGeneralModal}
              onSave={saveEditedQuestion}
              onChange={onChange}
            />
          </Row>
        )
        : <AddNewQuestion payload={payload} handleChangeVisible={handleChangeVisible} />}
    </div>
  );
};

QuestionnaireConfiguration.propTypes = {
  types: PropTypes.array,
  configuration: PropTypes.object,
  category: PropTypes.string,
  children: PropTypes.any,
  name: PropTypes.string,
  hideWeight: PropTypes.object,
  onChangeState: PropTypes.func,
};

QuestionnaireConfiguration.defaultProps = {
  types: [],
  configuration: {},
  category: '',
  children: null,
  name: '',
  hideWeight: {},
  onChangeState: () => {},
};

export default QuestionnaireConfiguration;
